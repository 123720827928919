'use client'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { useTranslations } from 'next-intl'
import step1 from '@/assets/images/home/guide-01.png'
import step2 from '@/assets/images/home/guide-02.png'
import step3 from '@/assets/images/home/guide-03.png'
import step4 from '@/assets/images/home/guide-04.png'
import step5 from '@/assets/images/home/guide-05.png'

function Instructions() {
  const t = useTranslations('home.Instructions')

  const ListData = [
    {
      id: 1,
      stt: '01',
      title: t('step_1'),
      description: t('step_1_description'),
      thumb: step1,
    },
    {
      id: 2,
      stt: '02',
      title: '',
      description: t('step_2'),
      thumb: step2,
    },
    {
      id: 3,
      stt: '03',
      title: '',
      description: t('step_3'),
      thumb: step3,
    },
    {
      id: 4,
      stt: '04',
      title: '',
      description: t('step_4'),
      thumb: step4,
    },
    {
      id: 5,
      stt: '05',
      title: '',
      description: t('step_5'),
      thumb: step5,
    },
  ]

  const [isActive, setIsActive] = useState<number>(1)

  useEffect(() => {
    const i = setInterval(() => {
      if (isActive == 4) {
        setIsActive(1)
      } else {
        setIsActive(isActive + 1)
      }
    }, 5000)

    return () => {
      clearInterval(i)
    }
  })

  return (
    <section
      className="flex justify-center py-20 overflow-x-hidden"
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <div className="container px-4 sm:px-8">
        <div className="relative z-20">
          <div className="flex items-center justify-between pb-14">
            <div>
              <h2 className="md:text-3xl text-4xl leading-5xl font-semibold my-5">
                {t('title')}
              </h2>
              <p className="text-base-gray-500 leading-8">{t('description')}</p>
            </div>
          </div>

          <div className="relative py-4">
            <div className="grid md:grid-cols-2 gap-32">
              <div className="">
                <ul>
                  {ListData.map((step) => (
                    <li
                      key={step.id}
                      className={clsx(
                        'border-l-4',
                        isActive == step.id
                          ? 'border-brand-solid px-6 py-4'
                          : 'px-6 py-4'
                      )}
                    >
                      <div className="grid gap-3">
                        <p
                          className={clsx(
                            isActive == step.id
                              ? 'text-brand-solid font-semibold text-base'
                              : 'text-base-gray-500 font-semibold text-base'
                          )}
                        >
                          {step.stt}
                        </p>
                      </div>
                      <div className="mt-3">
                        <h3 className="text-xl font-semibold text-primary mb-2">
                          {step.title}
                        </h3>
                        <p className="text-tertiary text-base">
                          {step.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-full h-full max-w-[544px] max-h-[648px] relative">
                <Image
                  alt="instructions rental car"
                  className="object-cover rounded-3xl"
                  fill={true}
                  src={ListData[isActive].thumb}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Instructions
