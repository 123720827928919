import FilterDatePropver from '@/components/pages/filter/hero/self-driving/FilterDatePopover'
import { CalendarSgv } from '@/icons-svg/SvgIcons'
import { useTranslations } from 'next-intl'

export default function DateTime() {
  const t = useTranslations('home.hero')

  return (
    <div className="w-full grid grid-cols-2 items-center text-center">
      <div className="text-start">
        <label
          className="text-base-gray-500 font-medium flex items-center justify-start gap-x-2"
          htmlFor="filter-start-date"
        >
          <CalendarSgv />
          <span className="hidden md:block">{t('ngay_nhan')}</span>
          <span className="block md:hidden">{t('ngay_nhan_mobile')}</span>
        </label>
        <div className="pl-2">
          <FilterDatePropver type={'start'} />
        </div>
      </div>
      <div className="py-4 text-start">
        <label
          className="text-base-gray-500 font-medium flex items-center justify-start gap-x-2"
          htmlFor="filter-end-date"
        >
          <CalendarSgv />
          <span className="hidden md:block">{t('ngay_tra')}</span>
          <span className="block md:hidden">{t('ngay_tra_mobile')}</span>
        </label>
        <div className="pl-2">
          <FilterDatePropver type={'end'} />
        </div>
      </div>
    </div>
  )
}
