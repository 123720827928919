'use client'
import React, { useEffect, useState } from 'react'
import ItemCar from '@/components/common/ItemCar'
import { Button } from '@/components/ui/button'
import carsApiRequest from '@/api-data/cars'
import carTypeApiRequest from '@/api-data/car-type'
import { clsx } from 'clsx'
import { getServerSession } from 'next-auth/next'
import { authOptions } from '@/auth'
import { useTranslations } from 'next-intl'

export default function ListCars() {
  const t = useTranslations('home.ListCar')

  const [carTypeActive, setCarTypeActive] = useState(0)
  const [listCars, setListCars] = useState<any>([])
  const [listCarType, setListCarType] = useState<any>([])

  const callApiCarType = async () => {
    const queryString = new URLSearchParams({
      page: '1',
      limit: '9',
    })

    const { payload } = await carTypeApiRequest.listCarType(
      queryString.toString()
    )
    setListCarType(payload.data.list)
    if (
      carTypeActive === 0 &&
      payload?.data?.list &&
      payload?.data?.list.length > 0
    ) {
      setCarTypeActive(payload?.data?.list[0].id)
    }
  }

  const callApiCars = async () => {
    const { payload } = await carsApiRequest.listCars({
      car_type_id: carTypeActive + '',
      page: 1,
      limit: 9,
    })
    setListCars(payload?.data?.list)
  }

  useEffect(() => {
    if (carTypeActive > 0) {
      callApiCars()
    }
  }, [carTypeActive])

  useEffect(() => {
    callApiCarType()
  }, [])

  return (
    <section className="flex justify-center pb-24 pt-14 bg-base-gray-50">
      <div className="container px-4 sm:px-8">
        <div className="mb-14">
          <h2 className="md:text-4xl text-3xl font-semibold text-base-gray-900">
            {t('title')}
          </h2>
          <p className="text-base-gray-500 text-xl my-5">{t('description')}</p>
        </div>
        <div className="w-full overflow-auto flex justify-start items-center mb-12 gap-x-2">
          {listCarType &&
            listCarType.map((carType: any) => (
              <Button
                onClick={() => setCarTypeActive(carType.id)}
                key={carType.id}
                variant="outline"
                className={clsx(
                  carTypeActive === carType.id
                    ? 'bg-brand-solid rounded-[100px] hover:bg-brand-solid py-2.5 px-4'
                    : 'bg-base-gray-50 rounded-[100px] text-base-gray-600 py-2.5 px-4'
                )}
              >
                <span
                  className={clsx(
                    carTypeActive === carType.id
                      ? 'text-white font-semibold py-1 text-base leading-6'
                      : 'font-semibold text-base leading-6'
                  )}
                >
                  {carType.type_name}
                </span>
              </Button>
            ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {listCars &&
            listCars.map((car: any) => <ItemCar key={car.id} car={car} />)}
        </div>
      </div>
    </section>
  )
}
